/* eslint-disable no-unreachable */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import adminService from 'services/admin.service';

import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import Select from 'components/atoms/Select';
import { LangConverter } from 'helpers/common';
import { useTranslation } from 'helpers/useTranslation';
import GridCol from 'components/atoms/GridCol';
import { ServicesHolder } from './CreateServicePackage.styles';

function CreateServicePackage({ onClose = () => {}, service }) {
  const [form] = useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);
  const { lang } = useContextHook(AuthContext, { lang: 1 });
  const { t } = useTranslation();
  console.log({ service });
  useEffect(() => {
    if (service) {
      const s = { ...service };
      const provider = {
        providerId: {
          label: service?.serviceId?.providerId?.providerName,
          value: {
            _id: service?.serviceId?.providerId?._id,
            providerName: service?.serviceId?.providerId?.providerName,
          },
        },
      };

      const serviceId = {
        label: `${t('Service Code')}: ${service?.serviceId?.serviceCode},
         ${t('Description')}: ${service?.serviceId?.description}`,
        value: service?.serviceId?._id,
      };
      s.providerId = provider;
      s.serviceId = serviceId;
      s.services = [
        {
          serviceCode: service?.serviceId?.serviceCode,
          description: service?.serviceId?.description,
          _id: service?.serviceId?._id,
          price: service?.serviceId?.price,
          providerId: {
            _id: service?.serviceId?.providerId?._id,
            providerName: service?.serviceId?.providerId?.providerName,
          },
        },
      ];

      form.setFieldsValue(s);
      form.setFieldsValue({ providerId: provider?.providerId });
      console.log({ s });
      setState(s);
    }
  }, [service]);
  console.log({ formValues: form.getFieldsValue() });
  const handleServicesSearch = async __ => {
    console.log({ __ });
    const providerId = state?.providerId?.providerId?.value?._id;
    try {
      const response = await adminService.getServices({
        page: 1,
        pageSize: 10,
        searchText: __,
        startDate: '',
        endDate: '',
        filterText: providerId,
      });

      const filteredService = response?.services?.find(itm => itm?.providerId?._id === providerId);
      setState(prev => ({ ...prev, services: [filteredService] }));
      const options = response?.services?.map(_ => ({
        value: _?._id,
        label: LangConverter(
          `Service Code: ${_?.serviceCode}, 
         Description: ${_?.description}`,
          lang.value,
        ),
      }));
      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };
  // when provider is selected populate the existing discounts if exists. If all selected then bring all serivces of providers.

  const handleProviderSearch = async __ => {
    try {
      const response = await adminService.getServiceProviders({
        page: 1,
        pageSize: 10,
        searchText: __,
        startDate: '',
        endDate: '',
        filterProviders: '',
      });
      const options = response?.serviceProviders?.map(_ => ({
        value: _,
        label: _?.providerName,
      }));
      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };

  const onSubmit = async () => {
    const payload = {
      serviceId: state?.serviceId?.value,
      units: state?.units,
      packagePrice: state?.packagePrice,
      name: state?.name,
    };
    console.log({ payload });
    // return;
    try {
      setLoading(true);
      const res = !service
        ? await adminService.createServicePackage(payload)
        : await adminService.updateServicePackage(service?._id, payload);
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: res?.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  const getServices = async provider => {
    try {
      const res = await adminService.getServiceByProvider(state?.resellerId?.value, provider);
      const services = res?.map(itm => {
        const discountedPrice = itm?.price - (itm?.price * itm?.discount) / 100;
        if (itm?.discount) {
          form.setFieldsValue({ [`discount_${itm?._id}`]: itm.discount });
        }
        return {
          label: itm?.serviceCode,
          value: itm?._id,
          price: itm?.price,
          discount: itm?.discount ?? 0,
          discountedPrice,
          description: itm?.description,
        };
      });
      return services;
    } catch (error) {
      Toast({
        type: 'error',
        message: error?.message,
      });
      return [];
    }
  };

  const onSelectProvider = async ({ target: { value } }) => {
    console.log({ value });
    try {
      const provider = { providerId: { label: value?.label, value: value?.value } };
      form.setFieldsValue(provider);
      let providerId = provider?.providerId?.value?._id;

      if (value?.value === 'all') {
        providerId = 'all';
      }

      await handleServicesSearch('', providerId);
      setState(prev => ({ ...prev, providerId: provider, serviceKey: Math.random() }));
      form.setFieldsValue({ serviceId: undefined });
      form.setFieldsError({ serviceId: { message: '' } });
    } catch (error) {
      Toast({
        type: 'error',
        message: error?.message,
      });
    }
  };

  console.log({ state });
  const handleDiscount = (serviceId, value) => {
    const updatedServices = state?.services?.map(itm => {
      if (itm?.value === serviceId) {
        const discountedPrice = itm?.price - (+value / 100) * itm?.price;
        form.setFieldsValue({
          [`discount_${serviceId}`]: value,
        });

        return { ...itm, discount: value, discountedPrice };
      }
      return itm;
    });
    setState(prev => ({ ...prev, services: updatedServices }));
  };

  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      onTouched={_ => {
        setState(__ => ({ ...__, ..._ }));
      }}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label={t('Service Provider')}
          name="providerId"
          open
          async
          defaultOptions
          filterOption={false}
          loadOptions={handleProviderSearch}
          onChange={onSelectProvider}
          placeholder={t('Select Service Provider')}
          rules={[{ required: true, message: t('Please select service provider') }]}>
          <Select />
        </Form.Item>
        <Form.Item
          sm
          type="text"
          label={LangConverter('Services', lang.value)}
          name="serviceId"
          key={state?.serviceKey}
          open
          async
          defaultOptions
          isDisabled={!state?.providerId?.providerId?.value?._id}
          filterOption={false}
          loadOptions={handleServicesSearch}
          placeholder={LangConverter('Select Service', lang.value)}
          rules={[{ required: true, message: LangConverter('Please select service', lang.value) }]}>
          <Select />
        </Form.Item>
      </Grid>
      <ServicesHolder>
        {state?.serviceId &&
          state?.services?.map(itm => (
            <Grid xs={1} md={12} gap={10}>
              <>
                <GridCol xs={1} sm={2}>
                  <Form.Item
                    type="text"
                    sm
                    label={t('Service Code')}
                    name="serviceId"
                    placeholder={t('Service Code')}
                    disabled
                    value={itm?.serviceCode}>
                    <Field />
                  </Form.Item>
                </GridCol>
                <GridCol xs={1} sm={2} md={3}>
                  <Form.Item
                    type="text"
                    sm
                    label={t('Service Description')}
                    name="description"
                    placeholder={t('Service Description')}
                    disabled
                    value={itm?.description}>
                    <Field />
                  </Form.Item>
                </GridCol>
                <GridCol xs={1} sm={2}>
                  <Form.Item
                    type="number"
                    sm
                    label="Units"
                    name="units"
                    placeholder={t('Units')}
                    rules={[
                      {
                        min: 0,
                        message: t('Minimum value should be 1'),
                      },
                      {
                        max: 100000,
                        message: t('Value should be less than or equal to 100000'),
                      },
                    ]}>
                    <Field />
                  </Form.Item>
                </GridCol>
                <GridCol xs={1} sm={2}>
                  <Form.Item
                    disabled
                    type="number"
                    sm
                    label={t('unit price')}
                    name="price"
                    value={itm?.price}
                    placeholder={t('Price')}
                    prefix="€">
                    <Field />
                  </Form.Item>
                </GridCol>
                <GridCol xs={1} sm={2} md={3}>
                  <Form.Item
                    disabled
                    type="number"
                    sm
                    label={t('Total Price')}
                    name="totalPrice"
                    placeholder={t('Total Price')}
                    value={state?.units ? itm?.price * Number(state.units) : itm?.price}
                    prefix="€">
                    <Field />
                  </Form.Item>
                </GridCol>
              </>
            </Grid>
          ))}
      </ServicesHolder>
      <GridCol xs={1}>
        <Form.Item
          sm
          type="text"
          label={t('Package Name')}
          name="name"
          placeholder={t('Enter Package Name')}
          rules={[{ required: true, message: t('Please enter package name') }]}>
          <Field />
        </Form.Item>
        <Form.Item
          type="number"
          disabled={!state?.units}
          sm
          label={t('Package Price')}
          name="packagePrice"
          placeholder={t('Package Price')}
          prefix="€"
          rules={[
            { required: true, message: t('Please enter package price') },
            {
              min: 1,
              message: t('Minimum value should be 1'),
            },
            {
              max: 999999999,
              message: t('Value should be less than or equal to 999999999'),
            },
          ]}>
          <Field />
        </Form.Item>
      </GridCol>
      <Button
        disabled={loading}
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        {service ? LangConverter('Update', lang.value) : LangConverter('Save', lang.value)}
      </Button>
    </Form>
  );
}

export default CreateServicePackage;
