import React, { useState } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import { LangConverter } from 'helpers/common';
import { useTranslation } from 'helpers/useTranslation';
import resellerService from 'services/resellers.service';
import Modal from 'components/molecules/Modal';
import Heading from 'components/atoms/Heading';
import { BtnHolder, Subtitle } from 'components/molecules/ConfirmationModal/ConfirmationModal.styles';

function SearchStudentTaxCode({ onClose = () => {}, onImportData = () => {} }) {
  const [form] = useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { lang } = useContextHook(AuthContext, { lang: 1, user: 1 });
  const { t } = useTranslation();

  const onSubmit = async data => {
    try {
      setLoading(true);
      const res = await resellerService.getStudentByTaxCode(data.codiceFiscale);
      setState(__ => ({ ...__, student: res.student }));
      setIsVisible(true);
      setLoading(false);
      Toast({
        type: 'success',
        message: res?.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  const handleImport = () => {
    onImportData(state.student);
    onClose();
  };

  return (
    <>
      <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
        <Grid
          xs={1}
          colGap={20}
          css={`
            align-items: center;
          `}>
          <Form.Item
            sm
            type="text"
            label={LangConverter('Tax Code', lang.value)}
            name="codiceFiscale"
            placeholder={LangConverter('Codice Fiscale', lang.value)}
            rules={[
              { required: true, message: LangConverter('Please enter tax code', lang.value) },
              { changeRegex: 'codice_fiscale' },
              { min: 16, message: LangConverter('Tax code must be 16 characters', lang.value) },
            ]}>
            <Field />
          </Form.Item>
        </Grid>
        <Button
          disabled={loading}
          loading={loading}
          type="primary"
          htmlType="submit"
          css={`
            margin-top: 20px;
          `}>
          {LangConverter('Search', lang.value)}
        </Button>
      </Form>
      <Modal isOpen={isVisible} setIsOpen={setIsVisible}>
        <>
          <Heading level={3} css="margin-bottom: 10px; font-weight: 500;">
            {t('Student Found')}
          </Heading>
          <Subtitle>{t('A student with this tax code already exists. Do you want to import the data?')}</Subtitle>
          <BtnHolder>
            <Button
              type="outline"
              width={130}
              onClick={() => {
                setIsVisible(false);
                onClose();
              }}>
              No
            </Button>

            <Button
              type="primary"
              width={130}
              onClick={async () => {
                handleImport();
              }}
              loading={loading}>
              Import Data
            </Button>
          </BtnHolder>
        </>
      </Modal>
    </>
  );
}

export default SearchStudentTaxCode;
